<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Cookie Policy"> </PageHeader>

    <div class="container-fluid">
      <div class="row mb-30">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <p>
              MPZMail Limited (“us”, “we”, or “our”) use cookies on transpond.io (the “Site”). 
              Our Cookie Policy explains what cookies are, how we use cookies, how third parties
               we may partner with may use cookies on the Site, your choices regarding cookies and further information about cookies.
            </p>
            <h3>What are cookies</h3>
            <p>
              Cookies are small text files that are placed on your computer by websites that you visit. 
              They are widely used in order to make websites work, or work more efficiently, as well as 
              to provide information to the owners of the Site.
            </p>
            <p>
              Cookies can be “persistent” or “session” cookies. 
            </p>

            <h3>Persistent Cookies</h3>
            <p>
              We use persistent cookies and session cookies on this Site.
               A persistent cookie is stored on a user’s device in-between browser sessions which allows the 
               preferences or actions of a user across the Site (or in some cases across different websites) 
               to be remembered. Persistent cookies may be used for a variety of purposes including remembering 
               preferences and choices when using the Site. We use persistent cookies to save your login information
                for future logins to the Site.

            </p>

            <h3>Session Cookies</h3>
            <p>
              A session cookie allows the Site to link your actions during a browser session. We use session cookies 
              to enable certain features of the Site, to better understand how you interact with the Site and to monitor
               aggregate usage by users and web traffic routing on the Site. Unlike persistent cookies, session cookies 
               are deleted from your computer when you log off from the Site and then close your browser.

            </p>

            <h3>Which cookies we use and why</h3>
            <p>
              We use the following cookies:
            </p>

            <p>
              <strong>Strictly necessary cookies.</strong><br/> 
              These cookies are required for the operation of our Site, such as log-in and a cookie that records your consent preferences.<br/><br/>

              <strong>Analytics and tracking cookies.</strong><br/>
               These cookies allow us to see how visitors move around our Site and help provide information on metrics, such as number of visitors, bounce rates etc.<br/><br/>
              
              <strong>Functional cookies.</strong><br/>
                These cookies are used to recognize you when you return to our Site. This enables us to personalize our content for you, such as language.<br/><br/>

              <strong>Marketing cookies.</strong><br/>
                These cookies are used to provide customized advertising based on pages you have visited previously. We may also share this information with third parties for this purpose.

            </p>
            <p>
              The table below explains the cookies we use and why we use each of them.
            </p>



            <div
              class="terms-table"
              style="margin-top: 20px; margin-bottom: 20px"
            >
              <table cellPadding="10" cellSpacing="0">
                <thead>

                  <th valign="top">Cookie Type</th>
                  <th valign="top" width="200">Provider</th>
                  <th valign="top">Purpose</th>
                  <th valign="top" width="200">Duration</th>
                </thead>
                <tbody>
                  <tr>
                    <th valign="top">Strictly necessary Cookies</th>
                    <td valign="top" >
                      Transpond<br/>
                      <small>(mpz)</small>
                    </td>
                    <td valign="top">
                      We use a cookie and local storage variables to store  login and account data, along with your preferences and 
                      settings for use on the Transpond Control Panel. These cookies and session storage are essential for the usage
                       of the Control Panel and you will be unable to use the platform without this cookie.
                    </td>
                    <td valign="top">30 days</td>
                  </tr>

                  <tr>
                    <th valign="top">Functional Cookies</th>
                    <td valign="top">
                      Stripe<br/>
                      <small>(__stripe_sid, __stripe_mid)</small>
                    </td>
                    <td valign="top">
                      We use Stripe as a payment gateway to handle payments made to and from the Transpond platform. 
                      Stripe uses cookies to ensure that the payment system works properly, detect and prevent fraud, 
                      and understand how people interact with Stripe. There are some cookies that are essential for 
                      Stripe to function properly. These Necessary Cookies provide secure access to the site and enable
                       page navigation. Other categories of cookies include Advertising Cookies, Analytics Cookies, and Preference Cookies.
                      <br/><br/>
                      You can find more information in the Stripe Cookies Policy, including how to opt-out or manage your cookie preferences. 
                      <a href="https://stripe.com/cookies-policy/legal" target="_blank" rel="noopener noreferrer">https://stripe.com/cookies-policy/legal</a>

                    </td>
                    <td valign="top">30 Minutes</td>
                  </tr>


                <tr>
                    <th valign="top">Analytics and Tracking Cookies</th>
                    <td valign="top">
                      Posthog<br/>
                      <small>(ph_phc_xxxx_posthog)</small>
                    </td>
                    <td valign="top">
                      These cookies are used to collect information about how visitors use our Site. We use the information to conduct analysis,
                      compile reports, to help us improve the Site and to optimize the Site experience. You can read the full Posthog privacy policy 
                      for more details about the cookies set and how Posthog uses this data at <a href="https://posthog.com/privacy" target="_blank" rel="noopener noreferrer">https://posthog.com/privacy</a>

                    </td>
                    <td valign="top">12 Months</td>
                  </tr>

                  <tr>
                    <th valign="top">Analytics and Tracking Cookies</th>
                    <td valign="top">
                      Google Analytics<br/>
                      <small>(_ga, _gid, _gat, _AEC, APISID, DV, HSID,NID, SAPISID, SID, SIDCC, SSID)</small>
                    </td>
                    <td valign="top">
                      These cookies are used to collect information about how visitors use our Site. We use the information to conduct analysis, compile reports, to help us improve the Site and to optimize the Site experience. You can read the full Google Analytics privacy policy at <a href="http://www.google.com/policies/privacy/" target="_blank">http://www.google.com/policies/privacy/</a> for more details about the cookies set and how Google uses this data. 
                    </td>
                    <td valign="top">
                      _ga: 12 months<br/>
                      _gid: 24 hours<br/>
                      _gat: 1 minute<br/>
                      _AEC: 12 months<br/>
                      APISID: 12 months<br/>
                      DV: 10 minutes<br/>
                      HSID: 12 months<br/>
                      NID: 6 months<br/>
                      SAPISID: 12 months<br/>
                      SID: 12 months<br/>
                      SIDCC: 12 months<br/>
                      SSID: 12 months
                    </td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
            <h3>Your choices regarding cookies</h3>
            <p>
              You can set up your browser options, to stop your computer accepting 
              cookies or to prompt you before accepting a cookie from the websites you visit. 
              If you do not accept cookies, however, you may not be able to use the whole of 
              the Site or all functionality of the Services.
            </p>
            <p>
              Another way to control cookies in your browser is by using "ad blocker" software. 
              These tools often give you control over specific services which you can allow or 
              block. We make best efforts to ensure that Transpond works with this software however
               it is not always possible for us to test all combinations of blocking. 
               If you spot an issue with an ad blocker please email us at <a href="mailto:support@transpond.io">support@transpond.io</a>.

            </p>
            <p>
              To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit 

              <a
                href="https://www.aboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
                >https://www.aboutcookies.org</a
              >
              or
              <a
                href="https://www.allaboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
                >www.allaboutcookies.org</a
              >. To opt out of being tracked by Google Analytics across all
              websites visit
              <a
                href="http://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noopener noreferrer"
                >http://tools.google.com/dlpage/gaoptout</a
              >.
            </p>

            <h3>Changes to our Cookie Policy</h3>
            <p>
              Any changes we may make to our Cookie Policy in the future will be posted on 
              this page and, where appropriate, notified to you by email. Please check back
               frequently to see any updates or changes to our Cookie Policy. This Cookie Policy 
               was last updated on December 12, 2024 and replaces any other Cookie Policy previously
                applicable from this date.
            </p>

            <h3>Contact Us</h3>
            <p>
              By Post:<br/>
              MPZMail Limited, 20 Dale Street, Manchester, M1 1EZ, England.
            </p>
            <p>By Email:<br/>
            
              <a href="mailto:support@transpond.io">support@transpond.io</a>
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "CookiePolicy",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Cookie Policy - Transpond",
        description: "Take a look at the Transpond Cookie Policy",
      },
    };
  },
};
</script>
