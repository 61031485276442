<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row between-sm pb-7 pt-9">
        <div class="col-xs-12 col-sm-4 mb-4 mb-sm-0">
          <div class="row">
            <a
              class="footer__social-link"
              href="https://twitter.com/Transpondio"
            >
              <TwitterIcon class="footer__social-icon"></TwitterIcon>
            </a>
            <a
              class="footer__social-link"
              href="https://www.facebook.com/transpond.io"
            >
              <FacebookIcon class="footer__social-icon"></FacebookIcon>
            </a>
            <a
              class="footer__social-link"
              href="https://www.instagram.com/transpond.io/"
            >
              <InstagramIcon class="footer__social-icon"></InstagramIcon>
            </a>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="row start-xs end-sm">
            <div class="footer__legal mb-4 mr-4 mb-sm-0">
              © {{ year }} Transpond. All Rights Reserved.
            </div>
            <div class="footer__legal-links">
              <router-link class="footer__legal-link" to="/terms"
                >Terms</router-link
              >
              <router-link class="footer__legal-link" to="/privacy"
                >Privacy</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- <CookieMessage></CookieMessage> -->
</template>

<script>
import TwitterIcon from "./Svgs/TwitterIcon";
import FacebookIcon from "./Svgs/FacebookIcon";
import InstagramIcon from "./Svgs/InstagramIcon";
// import CookieMessage from "@/components/CookieMessage";

export default {
  components: {
    TwitterIcon,
    FacebookIcon,
    InstagramIcon,
    // CookieMessage,
  },
  data() {
    let year = new Date().getFullYear();

    return {
      year: year
    };
  },
  ///component has mounted
  created() {

  },

  //functions
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
};
</script>
